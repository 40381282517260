<template>
    <v-card
        class="mb-2"
        :class="{ 'px-5 py-4 mb-5': $vuetify.breakpoint.mdAndUp }"
    >
        <v-row class="ma-0">
            <v-col cols="12" lg="9" xl="6" class="pa-0">
                <v-card-title>Custom Set Up</v-card-title>
                <v-card-title class="mb-4">
                    Setting Up Your Domain for the Reseller Platform
                </v-card-title>
                <v-card-subtitle>
                    To configure your domain to point to the reseller platform,
                    please follow the steps below depending on whether you’d
                    like to use the root domain (e.g., mydomain.org) or a
                    subdomain (e.g., marketing.mydomain.org):
                    <p class="font-weight-bold mt-4">
                        Using the Root Domain (e.g., mydomain.org):
                    </p>
                    <ol class="pb-2">
                        <li class="mb-2">Set an ALIAS or ANAME record.</li>
                        <li class="mb-2">
                            Point it to clientcabin.net (do not use an IP
                            address, as this may change over time).
                        </li>
                    </ol>
                    <p class="font-weight-bold mt-4">
                        Using a Subdomain (e.g., marketing.mydomain.org):
                    </p>
                    <ol class="pb-2">
                        <li class="mb-2">
                            Choose a subdomain (e.g., marketing) and set a CNAME
                            record for it.
                        </li>
                        <li class="mb-2">
                            Point this CNAME to clientcabin.net (again, do not
                            use an IP address).
                        </li>
                    </ol>
                    <p>
                        Once this is set up, please contact support to complete
                        the setup process.
                    </p>
                    <p>
                        If you have any questions or need assistance, don’t
                        hesitate to reach out.
                    </p>
                </v-card-subtitle>
                <v-card-title class="mb-4">
                    Optional: Download Your Portal’s HTML (Not Recommended)
                </v-card-title>
                <v-card-subtitle>
                    While you have the option to download the HTML version of
                    your portal,
                    <b>we recommend against this approach</b>
                    for the following reasons:
                    <p class="mt-4">
                        <b>Manual Updates:</b>
                        Changes or updates to the portal won’t automatically
                        apply to the downloaded version.
                    </p>
                    <p>
                        <b>Technical Complexity:</b>
                        Hosting the portal yourself requires managing a server,
                        security, and updates independently.
                    </p>
                    <p>
                        <b>Limited Support:</b>
                        We do not provide support for portals hosted on external
                        platforms.
                    </p>
                    <p>
                        We highly encourage using our hosted solution at
                        clientcabin.com for ongoing updates and support.
                        However, if you prefer to proceed with downloading,
                        click the button below and follow the setup instructions
                        in the included README.md file.
                    </p>
                    <v-btn @click="download">Download HTML</v-btn>
                </v-card-subtitle>
            </v-col>
        </v-row>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class ResellerPortal extends Vue {
    download() {
        Object.assign(
            document.createElementNS('http://www.w3.org/1999/xhtml', 'a'),
            {
                target: '_blank',
                rel: 'noopener noreferrer',
                href: '/reseller_sales_pages/download'
            }
        ).click();
    }
}
</script>
